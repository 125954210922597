<template>
  <div>
    <!-- about us page start here  -->
    <section
      id="hwa-about-us"
      class="site-about-view"
    >
      <div class="container">
        <div class="about-main-view">
          <div
            class="qoute-image"
            :style="{
              'background-image': `url(${getFullImage(about.about_img)})`,
            }"
          />
          <div class="about-text-view">
            <h1 class="page-heading-1">
              About
            </h1>
            <h1 class="heading-text">
              {{ about.title }}
            </h1>
            <p class="mb-3">
              {{ about.description }}
            </p>
            <div
              v-if="$route.path === '/'"
              class="site-button-group mt-3"
            >
              <div
                class="site-btn"
                @click="$router.push('/about').catch(()=>{})"
              >
                Learn More
              </div>
            </div>

            <!-- <appointment-links></appointment-links> -->
            <div class="mb-3" />
            <div
              v-if="$route.path !== '/'"
              class="site-button-group mt-3"
            >
              <div
                :class="`site-btn ${active_tab === 1 ? 'active' : ''}`"
                @click="ActivateTab(1)"
              >
                Our Mission
              </div>
              <div
                :class="`site-btn ${active_tab === 2 ? 'active' : ''}`"
                @click="ActivateTab(2)"
              >
                Our Vision
              </div>
              <div
                :class="`site-btn ${active_tab === 3 ? 'active' : ''}`"
                @click="ActivateTab(3)"
              >
                Our Goals
              </div>
            </div>

            <div v-if="$route.path !== '/'">
              <p v-if="active_tab === 1">
                {{ about.mission }}
              </p>
              <p v-if="active_tab === 2">
                {{ about.vision }}
              </p>
              <p v-if="active_tab === 3">
                {{ about.goal }}
              </p>

            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- about us page end here -->
  </div>
</template>

<script>
import AppointmentLinks from './AppointmentLinks.vue'

export default {
  components: { AppointmentLinks },
  data() {
    return {
      active_tab: 1,
    }
  },
  computed: {
    about() {
      return this.$store.getters['auth/aboutContent']
    },
  },
  methods: {
    getFullImage(img) {
      return `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${img}`
    },
    ActivateTab(data) {
      this.active_tab = data
    },
  },
}
</script>

<style>

</style>
