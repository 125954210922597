<template>
  <div>
    <!-- contact view start -->
    <section
      id="hwa-contact"
      class="contact-us-section"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6" />
          <div class="col-md-6">
            <!-- <span class="contact-main-title">Let's Connect</span> -->
            <h1 class="contact-title">
              Contact Us
            </h1>
            <div class="site-contact-view-card mt-3">
              <validation-observer ref="contactForm">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <div class="contact-form-box">
                          <feather-icon
                            class=""
                            icon="UserIcon"
                          />
                          <input
                            v-model="contact.name"
                            name="name"
                            placeholder="Name"
                          >
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-md-6">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <div class="contact-form-box">
                          <feather-icon
                            class=""
                            icon="MailIcon"
                          />
                          <input
                            v-model="contact.email"
                            name="email"
                            type="email"
                            placeholder="Email"
                          >
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>

                    <div class="col-md-6">
                      <div class="contact-form-box">
                        <feather-icon
                          class=""
                          icon="PhoneCallIcon"
                        />
                        <input
                          v-model="contact.phone"
                          name="phone"
                          placeholder="Phone"
                        >
                      </div>
                    </div>

                    <div class="col-md-6">
                      <validation-provider
                        #default="{ errors }"
                        name="Subject"
                        rules="required"
                      >
                        <div class="contact-form-box">
                          <feather-icon
                            class=""
                            icon="Edit3Icon"
                          />
                          <input
                            v-model="contact.subject"
                            name="subject"
                            placeholder="Subject"
                          >
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>

                    <div class="col-md-12">
                      <validation-provider
                        #default="{ errors }"
                        name="Message"
                        rules="required"
                      >
                        <div class="contact-form-box-2">
                          <feather-icon
                            class="text-area-icon"
                            icon="MessageSquareIcon"
                          />
                          <textarea
                            v-model="contact.message"
                            name="Message"
                            placeholder="Message"
                            rows="8"
                            cols="30"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>

                    <div class="col-md-12">
                      <div
                        class="contact-form-button"
                        @click="validationForm()"
                      >
                        <b-spinner
                          v-if="loading"
                          small
                          type="grow"
                        />
                        {{ loading?'Loading...':'Send Message' }}

                      </div>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- contact view end -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      contact: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      required,
      email,
    }
  },
  methods: {
    sendMessage() {
      this.loading = true
      this.$http
        .post('/api/contact-us', this.contact)
        .then(res => {
          this.loading = false
          this.$bvToast.toast('Message sent Successfully', {
            title: 'Contact Us',
            variant: 'success',
            solid: true,
          })
          this.contact = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
          }
          this.$refs.contactForm.reset()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.showError('Failed to submit contact details')
        })
    },
    validationForm() {
      this.$refs.contactForm.validate()
        .then(success => {
          if (success) {
            this.sendMessage()
          }
        })
    },
  },
}
</script>

<style>

</style>
