var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"contact-us-section",attrs:{"id":"hwa-contact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('h1',{staticClass:"contact-title"},[_vm._v(" Contact Us ")]),_c('div',{staticClass:"site-contact-view-card mt-3"},[_c('validation-observer',{ref:"contactForm"},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"contact-form-box"},[_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],attrs:{"name":"name","placeholder":"Name"},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value)}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"contact-form-box"},[_c('feather-icon',{attrs:{"icon":"MailIcon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],attrs:{"name":"email","type":"email","placeholder":"Email"},domProps:{"value":(_vm.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value)}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"contact-form-box"},[_c('feather-icon',{attrs:{"icon":"PhoneCallIcon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.phone),expression:"contact.phone"}],attrs:{"name":"phone","placeholder":"Phone"},domProps:{"value":(_vm.contact.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "phone", $event.target.value)}}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"contact-form-box"},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.subject),expression:"contact.subject"}],attrs:{"name":"subject","placeholder":"Subject"},domProps:{"value":(_vm.contact.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "subject", $event.target.value)}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"contact-form-box-2"},[_c('feather-icon',{staticClass:"text-area-icon",attrs:{"icon":"MessageSquareIcon"}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.message),expression:"contact.message"}],attrs:{"name":"Message","placeholder":"Message","rows":"8","cols":"30"},domProps:{"value":(_vm.contact.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "message", $event.target.value)}}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"contact-form-button",on:{"click":function($event){return _vm.validationForm()}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.loading?'Loading...':'Send Message')+" ")],1)])])])])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }