<template>
  <div class="links">
    <div
      class="links-buttons mb-2"
      @click="$router.push('/appointment').catch(() => {})"
    >
      >> Click here to discover if you can qualify to buy a luxury home with a lower down payment. <<
    </div>
    <div
      class="links-buttons mb-2"
      @click="$router.push('/appointment').catch(() => {})"
    >
      >> Click here to discover if you can qualify to buy a luxury home for lower monthly payments. <<
    </div>
    <div
      class="links-buttons mb-2"
      @click="$router.push('/appointment').catch(() => {})"
    >
      >> Click here to discover if you can qualify to buy a luxury home for a lower interest rate. <<
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
