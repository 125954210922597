<template>
  <div>

    <section class="site-slider-section">
      <!-- Carousel section start -->
      <b-carousel
        id="carousel-caption"
        controls
        fade
        :interval="6000"
      >
        <b-carousel-slide
          v-for="(item,index) in carousel.content"
          :key="index"
          :caption-html="`
          <h3 class='welcome-text'>${item.title}</h3>
          <p class='message-text'>${item.description||''}</p>
          <div class='caption-button-box'>
            <!-- <div class='caption-button outer'>Login</div> -->
            <!--<div class='caption-button'>Start Now</div>-->
          </div>`
          "
          class="slider-bg"
          :style="getImage(item.image)"
        />
      </b-carousel>

      <!-- Carousel section end -->
      <!-- <div class="site_qoute_banner">
        <div class="qoute-text">
          <h1>Get Mortgage Quote</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore</p>
          <div class="app-button">
            <span>Get Mortgage Quote</span>
          </div>
        </div>
        <div
          class="qoute-image"
          :style="{
            'background-image': `url(${require('@/assets/images/slider/mortgage-quote.jpg')})`,
          }"
        />
      </div> -->
    </section>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  computed: {
    carousel() {
      return this.$store.getters['auth/carouselContent']
    },
  },
  methods: {
    getImage(image) {
      return `background: url(${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${image})`
    },
  },
}
</script>

<style>

</style>
