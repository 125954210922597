<template>
  <div class="site-main-page-view">

    <!-- <topbar /> -->
    <navbar />
    <slider />
    <about />
    <service />
    <contact />
    <testimonial :reviews="reviews" v-if="reviews.length"/>
    <blog />
    <subscribe />
    <AppFooter />
  </div>
</template>

<script>

// import Topbar from './site-content/Topbar.vue'
import Navbar from './site-content/Navbar.vue'
import Slider from './site-content/Slider.vue'
import About from './site-content/About.vue'
import Service from './site-content/Service.vue'
import Contact from './site-content/Contact.vue'
import Testimonial from './site-content/Testimonial.vue'
import Subscribe from './site-content/Subscribe.vue'
import AppFooter from './site-content/AppFooter.vue'
import Blog from './site-content/Blog.vue'

export default {
  components: {
    Navbar,
    Slider,
    About,
    Service,
    Contact,
    Testimonial,
    Subscribe,
    AppFooter,
    Blog,
  },
  mounted() {
    this.getFaqsAndReviews()
    const id = this.$route.params?.id || this.$route.query?.id
    if (id) {
      setTimeout(() => {
        document.getElementById(id)
          .scrollIntoView()
      }, 500)
    }
  },
  computed:{
    reviews() {
      return this.$store.getters['auth/reviews']
    },
  },
  methods: {
    getFaqsAndReviews() {
      this.$http.get('api/faqs-and-reviews')
        .then(res => {
          this.$store.commit('auth/setFaqs', res.data)
          this.loaded = true
        })
    },
  },
}
</script>
